import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import AudioComponent from '../../../../components/AudioComponent/AudioComponent';
import IntroPage from '../../../../components/ContentType/IntroPage/IntroPage';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import PageBannerStyle from '../../../../components/PageBannerStyle/PageBannerStyle';
import ScrollPageNav from '../../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../../components/Seo/Seo';
import Layout from '../../../../Layout';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { getImage, removeHtmlTag, getBreadCrumb } from '../../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const InnovationProjets = ({ data }) => {
  const intl = useIntl();
  const imagesArray = data?.allImages?.edges;
  const pageData = data?.pageData?.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);
  let searchQuery = '';

  const [innerNavList, setInnerNavList] = useState([]);
  const [urlParam, setUrlParam] = useState('');
  const [allText, setAllText] = useState('');

  let sectionArr = [];
  let sectionCount = 0;
  let globalText = '';

  useEffect(() => {
    setInnerNavList(sectionArr);
    setAllText(removeHtmlTag(pageData.body?.processed + '.' + pageData.field_text_2?.processed + '.' + globalText));
  }, [globalText]);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof location !== 'undefined') {
      searchQuery = new URLSearchParams(location?.search).get('anchor') || '';
      setUrlParam(searchQuery);
    }
  }, []);

  return (
    <Layout imagesArray={imagesArray}>
      <Seo title={pageData.title} description={pageData.field_sous_titre} />
      <div
        className={classNames(
          'page_template',
          'n-2_innovation_projets'
        )}
      >
        <ScrollPageNav
          data={innerNavList}
          scrollTo={urlParam}
        />

        <PageBannerStyle
          visuel={getImage(imagesArray, pageData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid}
          scroll={true}
          breadcrumbData={{
            //grandParentPage: { title: 'Acceuile', url: '/' },
            parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
            currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
            locale: pageData.langcode
          }}
        >
          <div className="wrapper_page">
            <TitlePage
              color="color_white"
              title={pageData.title}
            />
            {
              pageData.field_text_2?.processed ? (
                <div className="col_2_fix">
                  <div className="col">
                    <div dangerouslySetInnerHTML={{ __html: pageData.body?.processed }} />
                  </div>
                  <div className="col">
                    <div dangerouslySetInnerHTML={{ __html: pageData.field_text_2?.processed }} />
                  </div>
                </div>
              ) : (
                <div className="col_single">
                  <div dangerouslySetInnerHTML={{ __html: pageData.body?.processed }} />
                </div>
              )
            }


            {allText && pageData.field_version_audio && allText.length > 30 && <AudioComponent
              text={intl.formatMessage({ id: 'detailsActivities.button.listen.label' })}
              textToRead={allText}
            />}

          </div>
        </PageBannerStyle>
        {pageData.relationships?.field_blocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__block_actualites':
              sectionCount++;
              sectionArr.push({
                title: '0' + sectionCount + '. ' + block.field_title?.processed,
                section: 'section-' + block.relationships?.field_anchor?.drupal_internal__tid
              });
              globalText += block.field_title?.processed + '.' + block?.body?.processed;
              return (
                <section className={classNames('section_content', 'section-' + block.relationships?.field_anchor?.drupal_internal__tid)} key={i}>
                  <div className="wrapper_page">
                    <TitleSection
                      title={block.field_title?.processed}
                      description={block.body?.processed}
                      type="line"
                    />
                  </div>
                </section>
              );

            case 'block_content__basic':
              globalText += block?.body?.processed;
              return (
                <div className="wrapper_page_xs" key={i}>
                  <TextVisuelTwoCol
                    key={i}
                    orderInverse={false}
                    extremeLeft={true}
                    alignTop={true}
                    visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                    text={block.body?.processed}
                    cta={
                      {
                        ctaText: block.field_link?.title,
                        ctaLink: block.field_link?.url
                      }
                    }
                  />
                </div>

              );

            case 'block_content__block_texte':
              sectionCount++;
              sectionArr.push({
                title: '0' + sectionCount + '. ' + block.field_title?.processed,
                section: 'section-' + block.relationships?.field_anchor?.drupal_internal__tid
              });
              return (
                <section className={classNames('section_content', 'section-' + block.relationships?.field_anchor?.drupal_internal__tid)} key={i}>
                  <ShapeBackground
                    top={block.field_border_top && 'left'}
                    color={block.field_backgroud_color === 'ice' ? 'catskill_white' : (block.field_backgroud_color === 'navy' ? 'bleu' : 'white')}
                    bottomInfinite={block.field_infinite_border}
                    bottom={block.field_border_bottom_display}
                  >
                    {block.body?.processed && block.relationships?.field_section.length === 0 ? <IntroPage

                      title={block.field_title?.processed}
                      subtitle={block.field_subtitle?.processed}
                      visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                      text={block.body?.processed}
                      size="border" //wrapper
                    />
                      :

                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          h2color={block.field_backgroud_color === 'navy' ? 'white' : undefined}
                        />
                        {
                          block.relationships?.field_section?.map((section, index) => {
                            globalText += section?.relationships?.field_bloc_texte[0]?.field_title?.processed + '.' + section?.relationships?.field_bloc_texte[0]?.field_bloc_text_1?.processed;

                            return (
                              <TextVisuelTwoCol
                                key={index}
                                extremeLeft={(section?.field_no_padding && section?.field_alignment === 'left') ? true : false}
                                extremeRight={(section?.field_no_padding && section?.field_alignment === 'right') ? true : false}
                                alignLeft={(!section?.field_no_padding && section?.field_alignment === 'left') ? true : false}
                                alignRight={(!section?.field_no_padding && section?.field_alignment === 'right') ? true : false}
                                orderInverse={section?.field_alignment === 'right' ? true : false}
                                visuel={getImage(imagesArray, section?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                                title={section.relationships?.field_bloc_texte[0]?.field_title?.processed}
                                text={section.relationships?.field_bloc_texte[0]?.field_bloc_text_1?.processed}
                                colorTitle={block.field_backgroud_color === 'navy' ? 'color_white' : undefined}
                                colorDescription={block.field_backgroud_color === 'navy' ? 'color_white' : undefined}
                              />
                            );
                          })
                        }
                      </div>}
                  </ShapeBackground>
                </section>
              );
          }
        })}

      </div>
    </Layout >
  );
};

export const query = graphql`
query InnovationTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!]) {
  pageData: allNodePage(
    filter: {langcode: {eq: $locale}, path: {alias: {eq: $slug}}}
  ) {
    edges {
      node {
        title
        field_version_audio
        field_text_2 {
          processed
        }
        body {
          processed
        }
        relationships {
          field_image {
            drupal_internal__mid
          }
          field_blocs {
            __typename
            ... on Node {
              ... on block_content__block_actualites {
                id
                field_title {
                  processed
                }
                body {
                  processed
                }
                relationships {
                  field_anchor {
                    drupal_internal__tid
                  }
                }
              }
              ... on block_content__basic {
                body {
                  processed
                }
                field_link {
                  title
                  url
                }
                relationships {

                  field_image {
                    drupal_internal__mid
                  }
                }
              }
              ... on block_content__block_texte {
                field_title {
                  processed
                }
                body {
                  processed
                }
                field_subtitle {
                  processed
                }
                field_backgroud_color
                field_border_top
                field_border_bottom_display
                field_infinite_border
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                  field_anchor {
                    drupal_internal__tid
                  }
                  field_section {
                    field_alignment
                    field_no_padding
                    relationships {
                      field_image {
                        drupal_internal__mid
                      }
                      field_bloc_texte {
                        field_title {
                          processed
                        }
                        field_bloc_text_1 {
                          processed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        field_type
        langcode
        path {
          alias
          langcode
        }
        drupal_id
      }
    }
  }

  allImages: allMediaImage(
    filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}
  ) {
    edges {
      node {
        drupal_internal__mid
        relationships {
          field_media_image {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                mobile: fixed(width: 456, height: 456) {
                  ...GatsbyImageSharpFixed
                }
                desktop: fixed(width: 1256, height: 814) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        langcode
      }
    }
  }
  allMenu :   allMenuLinkContentMenuLinkContent(
    filter: {langcode: {eq: $locale}}
  ) {
    edges {
      node {
        drupal_parent_menu_item
        drupal_id
        langcode
        link {
          url
        }
        menu_name
        title
      }
    }
  }
}
`;

export default InnovationProjets;
